import * as Sentry from '@sentry/nextjs';
import { LoginResult } from '@service/login.service';
import { UserSessionData as UserTokenData } from '@service/model/session';
import { GA } from '@shared/ga/ga';
import axios from 'axios';
import Cookie from 'js-cookie';
import { NextRouter, useRouter } from 'next/router';
import React, { useContext, useEffect, useState } from 'react';

const LEGACY_SESSION_COOKIE = 'Agroconsultas::Handlers::AuthCookie_Agroconsultas';
const JWT_COOKIE = 'token';

const connector = axios.create();

interface UserSessionData extends UserTokenData {
  fullName: string;
  iat: number;
}

interface CurrentUserContext {
  user?: UserSessionData;
  isLogged: boolean;
  loading?: boolean;
  signOut?: (redirectToHome?: boolean) => void;
  refreshToken?: () => Promise<void>;
}

const MAX_AGE_MS = 1000 * 60 * 60 * 24 * 10;

function decodeToken(token: string): UserSessionData {
  try {
    const splitToken = typeof token === 'string' ? token.split('.') : null;

    if (!splitToken) {
      return null;
    }

    const base64: string = splitToken[1].replace('-', '+').replace('_', '/');

    const data = JSON.parse(atob(base64)) as UserSessionData;

    if (!data.iat || data.iat < (Date.now() - MAX_AGE_MS) / 1000) {
      return null;
    }

    return data;
  } catch (error) {
    console.error(error);

    return null;
  }
}

function removeSessionCookies() {
  document.cookie = `${LEGACY_SESSION_COOKIE}=; Domain=; Path=/; Expires=Fri, 19 Apr 2002 04:35:23 GMT`;
  document.cookie = `${JWT_COOKIE}=; Domain=; Path=/; Expires=Fri, 19 Apr 2002 04:35:23 GMT`;
}

function globalSignOut(router?: NextRouter, redirectToHome?: boolean) {
  Sentry.setUser(null);
  removeSessionCookies();

  if (router && !redirectToHome) {
    router.reload();

    return;
  }

  window.location.href = '/';
}

const INITIAL_SESSION: CurrentUserContext = {
  isLogged: false,
  signOut: () => console.error('User already sign out'),
  refreshToken: () => Promise.reject('No session context'),
  loading: true,
};

const SessionContext = React.createContext<CurrentUserContext>(INITIAL_SESSION);

function fromToken(token: string): CurrentUserContext {
  const user = decodeToken(token);
  const userData: UserSessionData = user ? { ...user, fullName: `${user.name} ${user.lastName}` } : undefined;

  Sentry.configureScope((scope) => {
    scope.setUser({ email: userData.email, id: `${userData.id}`, username: userData.nickname });
  });

  return { user: userData, isLogged: !!userData };
}

async function fromCookie(): Promise<CurrentUserContext> {
  const res = await connector.get<LoginResult>('/api/login');

  return res.data?.token && fromToken(res.data.token);
}

const useCurrentUserFetcher: () => CurrentUserContext = () => {
  const [data, setData] = useState(INITIAL_SESSION);
  const router = useRouter();

  const signOut = (redirectToHome) => {
    globalSignOut(router, redirectToHome);
    setData(INITIAL_SESSION);
  };

  const refreshToken = async () => {
    setData({ ...data, loading: true });
    const token = Cookie.get(JWT_COOKIE);
    const legacyToken = Cookie.get(LEGACY_SESSION_COOKIE);

    if (!token && !legacyToken) {
      setData({ ...data, loading: false });

      return;
    }

    if (token && !legacyToken) {
      Cookie.remove(JWT_COOKIE);
      setTimeout(() => signOut(false), 5000);

      GA.event({
        action: 'close_user_session',
        category: 'click',
        label: 'closeSession',
        value: 1,
      });

      return;
    }

    if (!token) {
      setData({ ...data, loading: true, isLogged: true });
      fromCookie().then((newData) => setData({ ...newData, signOut, loading: false }));
    } else {
      setData({ ...fromToken(token), signOut });
    }
  };

  useEffect(() => {
    refreshToken().catch((e) => console.error(e));
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { ...data, refreshToken };
};

export const useSession = () => useContext(SessionContext);

export const SessionContextProvider: React.FC = ({ children }) => {
  const currentUser = useCurrentUserFetcher();

  return <SessionContext.Provider value={currentUser}>{children}</SessionContext.Provider>;
};
